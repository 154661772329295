import { Heading, Para } from './articleStyles';
import InfoCard from './InfoCard';
import Grid from '@material-ui/core/Grid';

const Projects = ({ data, ...props }) => {
  return (
    <section {...props}>
      <Heading>
        Development Projects
      </Heading>
      <Para>
        I always want to learn, and working on side-projects is a great way to do that.
        These projects will see only small changes over time and are meant to help showcase my skills
        in full-stack web development, the cloud, game development in Unity, software engineering and
        development in general.
      </Para>
      <Grid container spacing={2} direction="row" justify="center" alignItems="flex-start">
        {data?.map( (item, key) => {
          return (
            <InfoCard
              data={item}
              key={key}
            />
          );
        })}
      </Grid>
    </section>
  );
}

export default Projects;
import React from 'react';

import Home from './components/Home';

function App() {
  const [data, setData] = React.useState([]);

  React.useEffect( () => {
    (async () => {
      let path = `${process.env.PUBLIC_URL}/assets/data/data.json`;
      let response = await fetch(path, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });
      let result = await response.json();
      setData(result);
    })();
  }, []);

  return (
    <Home data={data} />
  );
}

export default App;

import React from 'react';
import Parent from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import styled from "styled-components";
import Footer from './Footer';

const _Container = ({ trademark, children, className, ...props }) => {

  return(
    <Parent
      maxWidth="md"
      {...props}
      style={{
        position: 'absolute',
        top: '100px',
        paddingBottom: '6em'
      }}
    >
      <Box
        style={{
          minHeight: '100vh',
          position: 'relative',
          width: '100%',
          margin: '0',
          paddingBottom: '1rem'
        }}
        className={className}
      >
        <Article>
          {children}
        </Article>
      </Box>
      <Footer trademark={trademark} />
    </Parent>
  );
}

const Container = styled(_Container)`
  &:before {
    content: '';
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    top: -2em; right: 0; bottom: 0; left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    z-index: -1;
  }
`;

const Article = styled.article`
  font-size: 1rem;
`;

export default Container;
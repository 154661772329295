import React from 'react';
import {
  useHistory,
  withRouter
} from 'react-router-dom';

import NavCard from './NavCard';

import Box from '@material-ui/core/Box';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import BuildIcon from '@material-ui/icons/Build';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '0.8em',
    position: 'relative',
    top:'3px',
    paddingRight:'2px'
  }
}));

const Navigation = ({ navCallback }) => {

  const ABOUT    = { key: "About",    value: 1 };
  const PROJECTS = { key: "Projects", value: 2 };
  const CAREER   = { key: "Career",   value: 3 };
  const DEFAULT  = { key: "",         value: 0 };

  const [selected, setSelected] = React.useState(DEFAULT.key);

  const styles = useStyles();

  let history = useHistory();

  let handleSetSelection = selection => {
    setSelected(selection.key);
    navCallback(selection.value);
  }

  return(
    <Box
      component={'nav'}
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      style={{
        minWidth: '90vw',
        minHeight: '150px',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(35,55,0,0.8))',
        pointerEvents: 'none',
        zIndex: 1000
      }}
    >
        <NavCard
          icon={<EmojiFoodBeverageIcon className={styles.button} />}
          title={ABOUT.key}
          selected={selected===ABOUT.key||selected===""}
          action={event=>handleSetSelection(ABOUT)}
          history={history}
        >
          {/* About Card Description */}
          Learn&nbsp;more about&nbsp;Chris
        </NavCard>

        <NavCard
          icon={<BuildIcon className={styles.button} />}
          title={PROJECTS.key}
          selected={selected===PROJECTS.key||selected===""}
          action={event=>handleSetSelection(PROJECTS)}
          history={history}
        >
          {/* Projects Card Description */}
          Discover&nbsp;some of&nbsp;Chris's projects
        </NavCard>

        <NavCard
          icon={<BusinessCenterIcon className={styles.button} />}
          title={CAREER.key}
          selected={selected===CAREER.key||selected===""}
          action={event=>handleSetSelection(CAREER)}
          history={history}
        >
          {/* Career Card Description */}
          See&nbsp;where Chris has been&nbsp;and where&nbsp;he is&nbsp;now
        </NavCard>
    </Box>
  );
}

export default withRouter(Navigation);
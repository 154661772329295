import React from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
// import { useWindowSize } from '../files';
import useWindowScrollPosition from '@rehooks/window-scroll-position';

/*
const percent = (dividend, divisor) => {
  if (dividend === 0 || divisor === 0) return 0;
  else {
    return dividend / divisor;
  }
};
*/

const tou = (value) => {
  return typeof value === 'undefined';
}

const ResizeObserver = window.ResizeObserver || Polyfill;

const Box = ({ translate, color, lighten = false, ...props}) => {
  const WIDTH  = 2,
        HEIGHT = 2,
        DEPTH  = 1;
  const SPIN_X = 0.002,
        SPIN_Y = 0.001;
  const TRANSITION_SPEED = 0.1;

  const mesh = React.useRef();
  const vector  = new THREE.Vector3(
    translate[0] ?? 0,
    translate[1] ?? 0,
    translate[2] ?? 0
  );

  useFrame( () => {
    let [rotation, position, matcolor] = [mesh.current.rotation, mesh.current.position, mesh.current.material.color];
    rotation.x += SPIN_X;
    rotation.y += SPIN_Y;
    position.lerp(vector, TRANSITION_SPEED);
    matcolor.lerp(
      lighten
      ? color.highlight
      : color.normal,
      TRANSITION_SPEED
    );
  });

  return (
    <mesh {...props} ref={mesh} scale={1}>
      <boxGeometry args={[WIDTH, HEIGHT, DEPTH]} />
      <meshStandardMaterial color={color.normal} metalness={0.8} roughness={0.5} />
    </mesh>
  );
}

const Dolly = () => {
  let vec = new THREE.Vector3();
  let scroll = useWindowScrollPosition({
    throttle: 0.001
  });

  useFrame( ({ camera }) => {
    vec.x = camera.position.x;
    vec.y = 0 - (scroll.y * 0.0025);
    vec.z = camera.position.z;
    camera.position.lerp(vec, 0.1);
  });
  return null;
}

const Scene = ({ active = 0 }) => {
  // const size = useWindowSize();

  const leftColor  = {
    normal: new THREE.Color("rgb(40, 0, 80)"),
    highlight: new THREE.Color("rgb(20, 16, 53)")
  };
  const midColor   = {
    normal: new THREE.Color("rgb(80, 0, 40)"),
    highlight: new THREE.Color("rgb(80, 16, 25)"),
  };
  const rightColor = {
    normal: new THREE.Color("rgb(80, 40, 0)"),
    highlight: new THREE.Color("rgb(70, 40, 10)"),
  };

  const left = [-4.5, -2, -1];
  const middle = [0, -2, -1];
  const right = [4.5, -2, -1];
  const centre = [0,0.5,1];

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: '0px',
      zIndex: '-1'
    }}>
      <Canvas
        resize={{polyfill: ResizeObserver}}
      >
        <ambientLight />
        <pointLight position={[20, 20, 10]} />
        <Box
          position={left}
          color={leftColor}
          lighten={active === 1}
          translate={active === 1 ? centre : left}
        />
        <Box
          position={middle}
          color={midColor}
          lighten={active === 2}
          translate={active === 2 ? centre : middle}
        />
        <Box
          position={right}
          color={rightColor}
          lighten={active === 3}
          translate={active === 3 ? centre : right}
        />
        <Dolly />
      </Canvas>
    </div>
  );
}

export default Scene;
import Typography from '@material-ui/core/Typography';

const Footer = ({ trademark = false }) => {
  const MIN_YEAR  = 2023;
  let currentYear = new Date().getFullYear();

  if (currentYear < MIN_YEAR)
    currentYear = MIN_YEAR;

  return (
    <Typography variant="caption" component="p" style={{ position: 'relative', bottom: '2em', marginTop: '2em' }}>
      {trademark && <>All trademarks, logos, or brands are properties of their respective owners<br /></>}
      Website Copyright &copy; 2021-{currentYear} by Christopher Robert Brech
    </Typography>
  )
}

export default Footer;
import { Heading, Para } from './articleStyles';
import InfoCard from './InfoCard';
import Grid from '@material-ui/core/Grid';

const Career = ({ data, ...props }) => {
  return (
    <section {...props}>
      <Heading>
        Experience and Career
      </Heading>
      <Para>
        Creating tomorrow's transformative tools, helpful applications, and explosive games.
      </Para>
      <Para>
        I am passionate about making a difference through teamwork and collaboration, and I am always eager to contribute to the success of teams and projects in ways that align with their vision, goals and values.
      </Para>
      <Para>
        Most recently, I have been focusing most of my energy on online turn-based multiplayer Unity experiences.
      </Para>
      <Grid container spacing={2} direction="row" justify="center" alignItems="flex-start">
        {data?.map( (item, key) => {
          return (
            <InfoCard
              data={item}
              key={key}
            />
          );
        })}
      </Grid>
    </section>
  );
}

export default Career;
// CSS
import styles from './css/App.module.css';
// Media
import logo from './media/logo.svg';
import Chris from './media/Avatar.jpg';
/*
import camosun from './media/camosun-logo-print.png';
import grades from './media/Grades-Final-2020.gif';
import onevoice from './media/OneVoiceLogo.png';
import bluebuffalo from './media/BlueShield_Canada-tagline-horizontal-small.png';
import service from './media/blueWalmartFutureLogos.png';
import platformerImage from './media/platformerImage.png';
import zombieImage from './media/zombieImage.png';
*/
// Functions
import useWindowSize from './functions/useWindowSize';

const Files = {
  styles,
  logo,
  /*
  images: {
    "camosun": camosun,
    "grades": grades,
    "onevoice": onevoice,
    "bluebuffalo": bluebuffalo,
    "service": service,
    "platformer": platformerImage,
    "zombie": zombieImage
  },
  games: {
    "platformer": "2D-Platformer-WebGL.html",
    "zombiegame": "3D-FPS-Zombie-WebGL.html"
  },
  */
  Chris
};

export default Files;
export {
  styles,
  logo,
  Chris,
  useWindowSize
}

/*
// INTERESTING IDEA TO REVISIT IN THE FUTURE
import React from 'react';

// Directories
const dir_media = './media/';
const dir_css = './css/';

// Import Methods
const ImportMedia = value => {
  return import(dir_media + value);
}

const ImportCSS = value => {
  return import(dir_css + value);
}

// Dynamic Import
const Styles = React.lazy(
  () => ImportCSS('App.module.css')
);

const Logo = React.lazy(
  () => ImportMedia('logo.svg')
);

// Export
export default {
  Styles,
  Logo
}

// END OF INTERESTING IDEA TO REVISIT IN THE FUTURE
*/
import React from 'react';
import { render as renderBB } from '@bbob/react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';

import styled from 'styled-components';
import '@fontsource/roboto';

import Files from '../files';

const useCardStyles = makeStyles({
  root: {
    maxWidth: 345,
    borderColor: '#000',
    borderRadius: '5px',
    margin: '2em'
  },
  media: {
    margin: '-1em -1em 0 -1em',
    width: 'calc(100% + 2em)'
  },
  tabs: {
    marginBottom: '1em'
  },
  paper: {
    width: 'min-content',
    padding: 0,
    margin: 0,
    borderTop: '4px solid white',
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'auto'
  },
  modalPadding: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: '1rem',
    border: 0,
    margin: '-4px 0 0 0'
  },
  modalImage: {
    maxHeight: '90vh',
    maxWidth: '80vw',
    width: 'auto'
  },
  modalText: {
    maxHeight: '90vh',
    maxWidth: '80vw',
    width: '50em',
    overflowY: 'auto',
    padding: '0 1em'
  },
  modalIFrame: {
    width: '80vw',
    height: '45vw',
    overflowX: 'hidden',
    overflowY: 'auto',
    border: 0,
    margin: 0,
    padding: 0
  },
  modalHeading: {
    position: 'relative',
    paddingRight: '1.5em'
  },
  modalClose: {
    position: 'absolute',
    top: '-0.5rem',
    right: '-0.5em'
  }
});

const Slice = ({ title, duration, children, ...props }) => {

  return (
    <div {...props}>
      <Typography variant="h5" component="h3">
        {title}
      </Typography>
      {typeof duration !== 'undefined' && duration !== "" &&
        <Typography variant="body2" color="textSecondary" component="article" gutterBottom>
          <strong>{duration}</strong>
        </Typography>
      }
      <Typography variant="body2" component="article" align="left">
        {children}
      </Typography>
    </div>
  );
}

const _InfoCard = ({ data /*, history = { push: ()=>{} }*/, className }) => {
  const classes = useCardStyles();
  const [slice, setSlice] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalBody, setModalBody] = React.useState(<></>);

  const handleOpenModal = (e,body) => {
    setModalBody(body);
    setIsModalOpen(true);
  }
  
  const handleCloseModal = (e,body) => {
    setIsModalOpen(false);
    setModalBody(<></>);
  }

  const handleChange = (event, newValue) => {
    setSlice(newValue);
  }

  const convertNewline = value => {
    let str = "" + value;
    let res = str.split("\n");
    return <>
      {res.map( (value, key) => {
        return (
          <React.Fragment key={key}>
            {value}<br />
          </React.Fragment>
        )
      })}
    </>;
  }

  /*
  let handleHistory = target => {
    history.push("/" + target);
  }
  */

  return (
    <Card className={`${classes.root} ${className}`} raised>
      <CardContent>
        {typeof data.image !== 'undefined'
          ? <CardMedia
              className = { classes.media }
              image     = { `${process.env.PUBLIC_URL}/assets/images/${data.image}` }
              title     = { data.imageTitle || "" }
              component = { 'img' }
            />
          : <Typography gutterBottom variant="button" align='center'>
              { data.imageTitle || "" }
            </Typography>
        }
        <Tabs
          value          = { slice }
          onChange       = { handleChange }
          indicatorColor = "primary"
          textColor      = "primary"
          centered
          className = { classes.tabs }
        >
          {data.tabs.map( (tab, key) => 
            <Tab label={tab.label} key={key} />
          )}
        </Tabs>
        {data.tabs.map( (tab, key) =>
          <Collapse in={slice===key} key={key}>
            <Slice title={tab.body.title || ""} duration={tab.body.subtitle || ""}>
              {convertNewline(tab.body.content || "")}
              {typeof tab.body.more !== 'undefined' &&
                <CardActions>
                  <ButtonGroup color="secondary" variant="outlined">
                    {tab.body.more.map( (item, key) => {
                      let element = <></>;
                      switch (item.type) {
                        case "url":
                          element = <Button
                                      key    = { key }
                                      size   = "small"
                                      href   = { item.content }
                                      target = "_blank"
                                      style  = {{ textAlign: 'center' }}
                                    >{ item.label }</Button>;
                          break;
                        case "img":
                          element = <Button
                                      key     = { key }
                                      size    = "small"
                                      color   = "primary"
                                      onClick = { event => handleOpenModal(
                                        event,
                                        <div className={classes.modalPadding}>
                                          <Typography align="left" variant="h6" className={classes.modalHeading}>
                                            {tab.body.title || tab.label} / <strong>{item.label}</strong>
                                            <IconButton
                                                aria-label= "close"
                                                component = "label"
                                                variant   = { 'outlined' }
                                                onClick   = { handleCloseModal }
                                                className = { classes.modalClose }
                                            ><CloseIcon /></IconButton>
                                          </Typography>
                                          <img src={`${process.env.PUBLIC_URL}/assets/images/${item.content}`} alt={item.label} className={classes.modalImage} />
                                        </div>
                                      )}
                                    >{ item.label }</Button>;
                          break;
                        case "text":
                          element = <Button
                                      key     = { key }
                                      size    = "small"
                                      color   = "primary"
                                      onClick = { event => handleOpenModal(
                                        event,
                                        <div className={classes.modalPadding}>
                                          <Typography align="left" variant="h6" className={classes.modalHeading}>
                                            {tab.body.title || tab.label} / <strong>{item.label}</strong>
                                            <IconButton
                                                aria-label= "close"
                                                component = "label"
                                                variant   = { 'outlined' }
                                                onClick   = { handleCloseModal }
                                                className = { classes.modalClose }
                                            ><CloseIcon /></IconButton>
                                          </Typography>
                                          <Typography align="left" className={classes.modalText}>
                                            {renderBB(`${item.content}`)}
                                          </Typography>
                                        </div>
                                      )}
                                    >{ item.label }</Button>
                          break;
                        case "iframe":
                          element = <Button
                                      key     = { key }
                                      size    = "small"
                                      color   = "primary"
                                      onClick = { event=> handleOpenModal(
                                          event,
                                          <iframe src={`${process.env.PUBLIC_URL + '/' + Files.games[item.content]}`} className={classes.modalIFrame}></iframe>
                                        )}
                                    >{ item.label }</Button>
                          break;
                        default: break;
                      }
                      return element;
                    })}
                  </ButtonGroup>
                </CardActions>
              }
            </Slice>
          </Collapse>
        )}
      </CardContent>
      <Modal
        open      = { isModalOpen }
        onClose   = { handleCloseModal }
        align     = { 'center' }
        className = { classes.modal }
      >
        <Paper elevation={3} variant={'outlined'} className={classes.paper}>
          {modalBody}
        </Paper>
      </Modal>
    </Card>
  );
}

const InfoCard = styled(_InfoCard)`
  background-color: rgba(255,255,255,0.7);
  backdrop-filter:  blur(40px);
  transition:       background-color 0.5s ease-out;
  &:hover {
    background-color: rgba(255,255,255,0.95);
  }
`;

export default InfoCard;
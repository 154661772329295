import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import '@fontsource/roboto';

const selected_pos = '0,3em';
const hover_pos = '0,5em';
const unselected_pos = '0,6.6em';

const useCardStyles = makeStyles({
  root: {
    borderColor: '#000',
    borderRadius: '10px 10px 0 0',
    pointerEvents: 'auto'
  }
});

const useHeightStyles = makeStyles({
  root: {
    height: '100%'
  }
});

const useCardContentStyles = makeStyles({
  portrait: {
    padding: '8px'
  },
  landscape: {
    padding: '16px'
  }
});

const _NavCard = ({ icon, title, action = e=>{}, history = { push: ()=>{} }, children, className }) => {
  const classes = useCardStyles();
  const heightClass = useHeightStyles();
  const cardContentClass = useCardContentStyles();
  const matches = useMediaQuery('(orientation: portrait)');

  let handleHistory = target => {
    history.push("/" + target);
  }

  return (
    <Card className={`${classes.root} ${className}`} onClick={e => {
        action(e);
        handleHistory(title);
      }} raised>
      <CardActionArea className={heightClass.root}>
        <CardContent className={`${heightClass.root} ${
            matches
            ? cardContentClass.portrait
            : cardContentClass.landscape
          }`}>
          <Typography variant="body1" component="h2">
            {icon}{title}
          </Typography>
          <Typography variant="body2" component="p">
            {children}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const NavCard = styled(_NavCard)`
  min-width: min-content;
  transform: translate(${
    props => props.selected
    ? selected_pos
    : unselected_pos
  });
  background-color: rgba(255,255,255,0.7);
  transition: transform 0.2s ease-in, background-color 0.5s ease-out;
  will-change: transform, background-color;
  cursor: pointer;
  &:hover {
    transform: translate(${
      props => props.selected
      ? selected_pos
      : hover_pos
    });
    background-color: rgba(255,255,255,0.95);
    transition: background-color 0s ease-out;
  }
`;

export default NavCard;
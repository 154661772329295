import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import '@fontsource/roboto';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '0 0 1rem 0',
    padding: '1rem 0 0 0'
  },
  subtitle: {
    margin: '-1rem 0 1rem 0',
    padding: 0
  },
  paragraph: {
    margin: '1rem 4em 1rem',
    textAlign: 'left',
    fontSize: '1em'
  }
}));

export const Heading = ({ children }) => {
  const muiStyles = useStyles();

  return (
    <Typography variant="h4" className={muiStyles.title}>
      {children}
    </Typography>
  );
}

export const Subheading = ({ children }) => {
  const muiStyles = useStyles();

  return(
  <Typography variant="button" component="p" className={muiStyles.subtitle}>
    {children}
  </Typography>
  );
}

const _Para = ({ children, className }) => {
  const muiStyles = useStyles();

  return (
    <Typography variant="body2" component="p" className={`${muiStyles.paragraph} ${className}`}>{/*
    */}{children}
    </Typography>
  );
};

export const Para = styled(_Para)`
  &:first-of-type::first-line {
    font-weight: 600;
  }
  &:first-of-type::first-letter {
    font-size: 1.7em;
    font-weight: 700;
    margin-right: -1px;
    line-height: 1;
  }
  &:last-of-type {
    padding-bottom: 1rem;
  }
  & em {
    display: inline-block;
    font-style: normal;
    background-image: linear-gradient(to right, #efefb6, #efefb6 20%, #afaf86 40%, #fff 60%, #afaf86 80%, #efefb6 100%);
    background-size: 300%;
    background-clip: text;
    -webkit-background-clip: text;
    background-position: 0% 0%;
    color: transparent;
    transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-10deg, 0deg);
    animation: textColor 3s infinite;
    font-weight: 600;
  }
  @keyframes textColor {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -150% 0%;
    }
  }
`;

const Pair = ({ title, children }) => (
  <>
    <Heading>{title}</Heading>
    <Para>{children}</Para>
  </>
);

export default Pair;
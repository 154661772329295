import { Heading, Para } from './articleStyles';
import InfoCard from './InfoCard';
import Grid from '@material-ui/core/Grid';
// let data = require('../data/data.json');

const Education = ({ data, ...props }) => {
  return (
    <section {...props}>
      <Heading>
        Education
      </Heading>
      <Para>
        Every class had value. Every lesson was an opportunity to expand the mind, practice existing knowledge, and discover a new angle.
        During my courses, my goal was to always deliver everything I was asked and a little bit more.
      </Para>
      <Para>
        To all of my Camosun College instructors, thank you for giving me the tools and problem-solving flexibility to tackle any problem.
      </Para>
      <Grid container spacing={2} direction="row" justify="center" alignItems="flex-start">
        {data?.map( (item, key) => {
          return (
            <InfoCard
              data={item}
              key={key}
            />
          );
        })}
      </Grid>
    </section>
  );
}

export default Education;
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from 'react-router-dom';

import Files from '../files';
import Navigation from './Navigation';
import Scene from './Background';
import Header from './Header';
import Container from './Container';

import About from './About';
import Projects from './Projects';
import Career from './Career';
import Education from './Education';

import Grow from '@material-ui/core/Grow';

const { App, AppHeader } = Files.styles;
// const logo = Files.logo;

const Home = ({ data }) => {
  const [current, setCurrent] = React.useState(0);

  return(
    <div className={App}>
      <Header />
      <Router>
        <Navigation navCallback={setCurrent} />
        <header className={AppHeader}>
          <Switch>
            <Route
              exact path="/About"
              render={ () => {
                setCurrent(1);
                return (<></>);
              }}
            />
            <Route
              exact path="/Projects"
              render={ () => {
                setCurrent(2);
                return (<></>);
              }}
            />
            <Route
              exact path="/Career"
              render={ () => {
                setCurrent(3);
                return (<></>);
              }}
            />
            <Route
              exact path="/"
              render={ () => {
                setCurrent(0);
                return (<></>);
              }}
            />
          </Switch>
          <Scene active={current} />
          <Grow in={current===1} mountOnEnter unmountOnExit>
            <Container>
              <About />
            </Container>
          </Grow>
          <Grow in={current===2} mountOnEnter unmountOnExit>
            <Container>
              <Projects data={data?.projects} />
            </Container>
          </Grow>
          <Grow in={current===3} mountOnEnter unmountOnExit>
            <Container trademark={true}>
              <Career data={data?.career} />
              <Education data={data?.education} />
            </Container>
          </Grow>
          {/*
            <img src={logo} className={AppLogo} alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload. lol.
            </p>
            <a
              className={AppLink}
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          */}
        </header>
      </Router>
    </div>
  )
};

export default Home;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { styles, Chris, logo } from '../files';
import '@fontsource/roboto';

const AppLogo = styles.AppLogo;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 999
  },
  title: {
    flexGrow: 1,
    paddingLeft: '1rem'
  },
  subtitle: {
    flexGrow: 1
  },
}));

const avatarStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    width: '40px',
    left: 0,
    transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, left 0.5s ease-in-out'
  },
  selected: {
    height: 'auto',
    width: '60px',
    left: '-10px',
    transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, left 0.5s ease-in-out'
  }
}));

export default function ButtonAppBar() {
  const [avatarSelected, setAvatarSelected] = React.useState(false);

  const classes = useStyles();
  const avatarClass = avatarStyles();

  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar
            onMouseOver={e=>setAvatarSelected(true)}
            onMouseOut={e=>setAvatarSelected(false)}
        >
          <Avatar
            alt="Christopher Robert Brech"
            src={Chris}
            className={avatarSelected
              ? avatarClass.selected
              : avatarClass.root
            } />
          <Typography variant="h5" className={classes.title} align="left">
            Christopher Robert Brech
          </Typography>
          <Typography variant="h6" className={classes.subtitle} align="right" color="textSecondary">
            { /* Built with React, Material-UI and ThreeJS */ }
          </Typography>
          <img src={logo} className={AppLogo} alt="logo" />
        </Toolbar>
      </AppBar>
    </div>
  );
}
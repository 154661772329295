import { Heading, Subheading, Para } from './articleStyles';

const About = (props) => {
  return (
    <section {...props}>
      <Heading>
        Why, hello there! I'm&nbsp;Chris
      </Heading>
      <Subheading>Explore. Discover. Practice. Improve.</Subheading>
      <Para>
        I am a game developer, a full-stack web developer, and a flexible team player.
      </Para>
      <Para>
        I love every opportunity to learn and to share that knowledge with others.
      </Para>
      <Para>
        In my college computer science program, I averaged close to A+ with an <em>8.738 GPA</em> in the program.
      </Para>
      <Para>
        Most recently, I program in <em>C#</em> and script using <em>ES6 JavaScript.</em> I built this ePortfolio with <em>React,</em> <em>Material-UI,</em> and <em>Three.js</em>
        {' '}(among other packages) and host on <em>Amazon Web Services.</em>
      </Para>
      <Para>
        With a quick refresher, I am comfortable with <em>Python,</em> <em>PHP,</em> <em>C++,</em>
        {' '}and <em>Java,</em> among others. If I don't already know it, I would love to learn it!
      </Para>
      <Para>
        I have developed on <em>Windows,</em> <em>Linux</em> (Ubuntu and CentOS), and <em>MacOS.</em>
      </Para>
      <Para>
        I like to stay informed with current and upcoming computer technology and various sciences.<br></br>
        I highly recommend Alastair Reynolds for science fiction novels, and BeatSaber for virtual reality.<br></br>
        On occasion, I enjoy casual rounds of 9-hole golf or badminton.
      </Para>
    </section>
  );
}

export default About;